import axios from "axios"
import { PlansModel } from "../models/Plans"
import { env } from "../Utils/Environment"
import { ErrorCodesGetModel } from "../models/ErrorCodes"

const API = env('MARKETSMS_ENDPOINT')

const getHeadersAuth = (content_type?: string) => {
    const headers = {
        'Content-Type': content_type ?? 'application/json',
        'Device-Id': localStorage.getItem('device_id'),
        // 'Authorization': 'Bearer ' + getToken(),
    }
    return headers;
}

export const getPlansByStatus = async (): Promise<PlansModel> => {
    try {
        var response = await axios.get(API + '/plans/1', { headers: getHeadersAuth() })
        return response.data[0];

    } catch (error: any) {
        return processErrorResponse(error)
    }
}

export const getErrorCodes = async (): Promise<ErrorCodesGetModel> => {
    try {
        var response = await axios.get(API + '/errorcodes?page=all', { headers: getHeadersAuth() })
        return response.data[0];

    } catch (error: any) {
        return processErrorResponse(error)
    }
}

export const saveDevice = async (body: Object): Promise<any> => {
    try {
        // const headers = await getHeadersApiKey();
        const headers = {
            'Content-Type': 'application/json'
        }

        const response = await axios.post(API + '/device', body, { headers });
        return response.data[0];
    } catch (error: any) {
        return processErrorResponse(error)
    }
}

function processErrorResponse(error: any) {
    if (error.response && (error.response.status === 400 || error.response.status === 401 || error.response.status === 500)) {
        if (error.response.status === 401) {
            console.log(error);
            // userLogout()
        } else {
            return error.response.data[0];
        }
    } else {
        throw error;
    }
}