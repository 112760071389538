import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import { Providers } from './redux/providers';
import React from 'react';
import { RouterProvider } from 'react-router-dom';
// import { Provider } from 'react-redux';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
  {/* <Providers>
    <App />
  </Providers> */}
   {/* <RouterProvider > */}
     <App />
   {/* </RouterProvider>, */}

</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
