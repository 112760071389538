import React, { ReactNode, useEffect, useRef, useState } from "react";
import {
  CAvatar,
  CBadge,
  CButton,
  CCollapse,
  CDropdown,
  CDropdownDivider,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CFooter,
  CForm,
  CFormInput,
  CHeader,
  CHeaderDivider,
  CImage,
  CNav,
  CNavbar,
  CNavbarBrand,
  CNavbarNav,
  CNavbarToggler,
  CNavItem,
  CProgress,
  CRow,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarToggler,
} from "@coreui/react-pro";
import CIcon from "@coreui/icons-react";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

import {
  CContainer,
  CHeaderBrand,
  CHeaderNav,
  CHeaderToggler,
  CNavLink,
} from "@coreui/react-pro";
import {
  cilAccountLogout,
  cilBasket,
  cilBell,
  cilChart,
  cilCreditCard,
  cilEnvelopeOpen,
  cilList,
  cilMenu,
  cilSpeedometer,
  cilUser,
  cilUserFollow,
  cilUserUnfollow,
} from "@coreui/icons";
import logo from "../assets/icons/logo.svg";
import logoFull from "../assets/icons/logo-full.png";
import logoLight from "../assets/icons/logo-light.png";
import angular from "../assets/angular.jpg";
import init from "../assets/init.png";
import end from "../assets/end.png";
import { Link, NavLink } from "react-router-dom";
import { env } from "../Utils/Environment";
import { BIcon } from "../components/icons/BIcon";

interface NavBarProps {
  children: ReactNode; // Definición de children como ReactNode
}

const NavBar: React.FC<NavBarProps> = ({ children }) => {

  const year = new Date().getFullYear();

  const [visible, setVisible] = useState(false)
  return (
    <>
      {/* <div
        className="wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent overflow-hidden" style={{backgroundImage: `url(${end})`}}
      > */}
      {/* <div
        className="wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent overflow-hidden"
        style={{
          backgroundImage: `url(https://i.pinimg.com/originals/44/92/a1/4492a1c0c17ffb7f37a83dc2a6306aee.jpg)`,
          backgroundSize: "cover", // Hace que la imagen cubra completamente el fondo
          backgroundPosition: "center", // Centra la imagen en el contenedor
        }}
      > */}
      <div
        className="wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent overflow-hidden"
        style={{
          backgroundImage: `url(https://i.pinimg.com/564x/b5/52/44/b55244976358635917cd65038e735a63.jpg)`,
          backgroundSize: "cover", // Hace que la imagen cubra completamente el fondo
          backgroundPosition: "center", // Centra la imagen en el contenedor
        }}
      >

        {/* <div
        className="wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent overflow-hidden"
        style={{
          backgroundImage: `url(https://i.pinimg.com/originals/81/56/e4/8156e410626b5f764c651826da344da9.jpg)`,
          backgroundSize: "cover", // Hace que la imagen cubra completamente el fondo
          backgroundPosition: "center", // Centra la imagen en el contenedor
        }}
      > */}






        {/* https://i.pinimg.com/originals/44/92/a1/4492a1c0c17ffb7f37a83dc2a6306aee.jpg */}
        <CNavbar expand="lg" className="py-4  fs-6" style={{ padding: "0 5%" }}>
          <CContainer fluid>
            <CNavbarToggler
              aria-label="Toggle navigation"
              className="bg-light"
              aria-expanded={visible}
              onClick={() => setVisible(!visible)}
            />
            <CNavbarBrand className="fs-2 fw-bold d-flex">
              <CNavLink component={NavLink} to="/">
                <CImage src={logo} height={32} className="cursor-pointer" />
              </CNavLink>
              <CNavLink component={NavLink} to="/" className="ms-2 pt-1 ">
                <span >Marketsms</span>
              </CNavLink>
            </CNavbarBrand>
            <CCollapse className="navbar-collapse" visible={visible}>
              <CNavbarNav className="ms-auto mb-2 mb-lg-0 py-2 fw-semibold">
                {/* <CDropdown variant="nav-item" popper={false} className="rounded px-4 header-items">
                  <CDropdownToggle color="secondary" >Servicios</CDropdownToggle>
                  <CDropdownMenu>
                    <CDropdownItem>
                      <CNavItem>
                        <CNavLink to="/services/whatsapp" className="p-0 py-1" component={NavLink} >
                          <BIcon icon="whatsapp" className="me-3 text-success" /><strong>WhatsApp</strong>
                          <p className="fs-8 mt-2 mb-1">Alcanza a tu audiencia con mensajes personalizados <br /> y OTP para marketing efectivo.</p>
                          <ul>
                            <li className="p-1">Marketing</li>
                            <li className="p-1">Individuales</li>
                            <li className="p-1">Otp</li>
                          </ul>
                        </CNavLink>
                      </CNavItem>
                    </CDropdownItem>
                    <CDropdownItem>
                      <CNavItem>
                        <CNavLink to="/services/sms" className="p-0 py-1" component={NavLink} >
                          <BIcon icon="chat-left-dots" className="me-3 text-warning" /><strong>Sms</strong>
                          <p className="fs-8 mt-2 mb-1">Llega a tus clientes en cualquier lugar con SMS <br /> personalizados y OTP para marketing directo</p>
                          <ul>
                            <li className="p-1">Marketing</li>
                            <li className="p-1">Individuales</li>
                            <li className="p-1">Otp</li>
                          </ul>
                        </CNavLink>
                      </CNavItem>
                    </CDropdownItem>
                  </CDropdownMenu>
                </CDropdown> */}
                <CNavItem>
                  <CNavLink component={NavLink} to="/plans" className="rounded px-4 header-items">
                    Precios
                  </CNavLink>
                </CNavItem>
                <CDropdown variant="nav-item" popper={false} className="rounded px-4 header-items">
                  <CDropdownToggle color="secondary" >Desarrolladores</CDropdownToggle>
                  <CDropdownMenu>
                    <CDropdownItem href={`${env('PORTAL_URL')}/developer/apis`}>Documentación api</CDropdownItem>
                    <CDropdownItem className="py-0">
                      <CNavItem>
                        <CNavLink to="/errorcodes" className="p-0 py-1" component={NavLink} >
                          Códigos de error
                        </CNavLink>
                      </CNavItem>

                    </CDropdownItem>
                    {/* <CDropdownDivider /> */}
                    {/* <CDropdownItem href="#">Blog Desarrolladores</CDropdownItem> */}
                  </CDropdownMenu>
                </CDropdown>
                {/* <CNavItem>
                  <CNavLink to="/about" component={NavLink} className="rounded mx-2 ps-2  header-items">
                    Nosotros
                  </CNavLink>
                </CNavItem> */}
                <CNavItem>
                  <CNavLink to="/contactus" component={NavLink} className="rounded px-4 header-items">
                    Contactanos
                  </CNavLink>
                </CNavItem>

                <CNavItem>
                  <CNavLink to={`${env('PORTAL_URL')}/login`} component={NavLink} className="rounded px-4 header-items">
                    Iniciar sesión
                  </CNavLink>
                </CNavItem>
              </CNavbarNav>
              <div className="d-flex">
                <CButton type="submit" color="info" className="fw-semibold rounded-pill" href={`${env('PORTAL_URL')}/signup`}>
                  Registrarse
                </CButton>
              </div>
            </CCollapse>
          </CContainer>
        </CNavbar>
        <CHeaderDivider />

        <CContainer fluid className="body flex-grow-1">
          {children}
        </CContainer>
        {/* <div className="body flex-grow-1 px-5 overflow-hidden" style={{ overflowY: 'hidden' }}>{children}</div> */}
        <CFooter>

          <div>
            <Link
              to={`${env('PUBLIC_URL')}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              MarketSms
            </Link>

            <span className="ms-1">
              &copy; {year} Todos los derechos reservados.
            </span>
          </div>
          <div className="ms-auto">

            <Link
              to="/termsandconditions"
              // target="_blank"
              rel="noopener noreferrer"
            >
              Teminos y condiciones
            </Link>
          </div>
          <div className="ms-auto">

            <Link
              to="/policyprivacy"
              // target="_blank"
              rel="noopener noreferrer"
            >
              Politica de privacidad
            </Link>
          </div>
        </CFooter>
      </div >
    </>
  )
}

export default NavBar;