import { CBadge, CCard, CCardBody, CSmartTable, CTabContent, CTabPane } from "@coreui/react-pro";
import React, { useEffect, useState } from "react";
import { ErrorCodesItems } from "../../models/ErrorCodes";
import * as MarketsmsService from "../../services/MarketsmsService";
import { getBadge } from "../../Utils/BaseApp";

interface ErrorCodesProps { }

export const ErrorCodes: React.FC<ErrorCodesProps> = ({ }: ErrorCodesProps) => {
    const [errorCodes, setErrorCodes] = useState<ErrorCodesItems[] | []>([]);

    const getErrorCodes = async () => {
        MarketsmsService.getErrorCodes().then((response) => {
            if (response) {
                if (response.status === 200) {
                    setErrorCodes(response.data.items);
                }
            }
        });
    };

    useEffect(() => {
        getErrorCodes();
    }, []);

    const columns = [
        {
            key: "code",
            label: "Código",
            _style: { width: "5%" },
        },
        {
            key: "message",
            label: "Mensaje",
            _style: { width: "20%" },
        },
        {
            key: "description",
            label: "descripción",
            _style: { width: "30%" },
        },
        {
            key: "cause",
            label: "Causa",
            _style: { width: "25%" },
        },
        {
            key: "code_app",
            label: "Código App",
            _style: { width: "10%" },
        },
        {
            key: "status_code",
            label: "Estado",
            _style: { width: "10%" },
        }
    ];

    console.log("object");
    return (
        <>
            <CCard className="my-4">
                <CCardBody>
                    <CTabContent className="rounded-bottom">
                        <CTabPane
                            className="p-3 active preview show"
                            role="tabpanel"
                            id="preview-578"
                        >
                            <CSmartTable
                                activePage={2}
                                cleaner
                                clickableRows
                                columns={columns}
                                columnFilter
                                columnSorter
                                loading={errorCodes === null ? true : false}
                                items={errorCodes ?? []}
                                itemsPerPageSelect
                                itemsPerPage={10}
                                pagination
                                scopedColumns={{
                                    status_code: (error_code: ErrorCodesItems) => (
                                        <td>
                                            <CBadge color={getBadge(error_code.status_code)}>
                                                {error_code.status_code}
                                            </CBadge>
                                        </td>
                                    )
                                }}
                                sorterValue={{ column: "status", state: "asc" }}
                                tableFilter
                                tableProps={{
                                    className: "add-this-class",
                                    responsive: true,
                                    striped: true,
                                    hover: true,
                                }}
                                tableBodyProps={{
                                    className: "align-middle",
                                }}
                            />
                        </CTabPane>
                    </CTabContent>
                </CCardBody>
            </CCard>
        </>
    );
};
