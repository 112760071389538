import React from 'react';
import { CFormCheck, CImage } from '@coreui/react-pro';
import { node } from 'prop-types';

interface RadioOption {
    id: number;
    image_src: string;
    text: string;
    description: string;
}

interface RadioComponentProps {
    options: RadioOption[];
    selectedOptionId: number;
    onChange: (id: number) => void;
}

const RadioComponent: React.FC<RadioComponentProps> = ({ options, selectedOptionId, onChange }) => {
    const handleOptionChange = (id: number) => {
        console.log(id);
        onChange(id);
    };

    return (
        <>
            {options.map((option) => (

                <div className="col-md-4"
                onClick={() => handleOptionChange(option.id)}
                >
                    <div className={`card h-100 cursor-pointer  ${selectedOptionId === option.id ?'border-info':''}`} key={option.id}>
                        <div className="card-body">
                            <h4 className="card-title"> <img src={option.image_src} alt="" height={24}/>&nbsp;&nbsp;{option.text}</h4>
                            <p className="card-text mt-3">{option.description}</p>
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
};

export default RadioComponent;
