import { CContainer } from "@coreui/react-pro";
import React from "react";

interface WhatsappProps { }

export const Whatsapp: React.FC<WhatsappProps> = ({ }: WhatsappProps) => {

  return (
    <>
      <CContainer>
        <h1 className="text-center my-3 fw-bold">
          WhatsApp
        </h1>
      </CContainer>
    </>
  );
};
