import { CCarousel, CCarouselCaption, CCarouselItem, CContainer, CImage } from "@coreui/react-pro";
import React from "react";
import textMessaging from "../../assets/home/text-messaging.png";
import phone from "../../assets/home/phone.svg";
import send from "../../assets/home/send.svg";
import alert from "../../assets/home/alert.svg";
import subscribers from "../../assets/home/subscribers.svg";
import segmentation from "../../assets/home/subs-segmentation.svg";
import checks from "../../assets/home/checks.svg";
import borderArrow from "../../assets/home/border-arrow.svg";
import home from "../../assets/home/home.svg";
import hearth from "../../assets/home/hearth.svg";
import bank from "../../assets/home/bank.svg";
import restaurants from "../../assets/home/restaurants.svg";
import schools from "../../assets/home/schools.svg";
import sales from "../../assets/home/sales.svg";
import { env } from "../../Utils/Environment";

interface TermsAndConditionsProps { }

export const TermsAndConditions: React.FC<TermsAndConditionsProps> = ({ }: TermsAndConditionsProps) => {
  return (
    <>

      <CContainer>
        <h1 className="my-5 text-center">Terminos y Condiciones</h1>
        <p>Los presentes términos y condiciones regulan la prestación de servicios (el "Contrato”) que rigen la relación mercantil entre la plataforma LabsMobile (en adelante, “MARKETSMS”), y el Cliente.</p>
        <h3>Clausulas</h3>
        <ol>
          <li className="text-dk-blue fs-6 fw-semibold mb-2 mt-4">OBJETO</li>
          Este contrato establece los términos y condiciones que regulan la prestación del servicio de envío de campañas de publicidad (en adelante, el "Servicio") por parte de MarketSms al Cliente. Define los derechos y obligaciones de ambas partes.
          <li className="text-dk-blue fs-6 fw-semibold mb-2 mt-3">DURACIÓN</li>
          El contrato tiene una duración inicial de un (1) año desde su suscripción, extendiéndose automáticamente por períodos idénticos, salvo causas de terminación establecidas en la cláusula 11.
          <li className="text-dk-blue fs-6 fw-semibold mb-2 mt-3">CONTRATACIÓN Y CANCELACIÓN DEL SERVICIO</li>
          La contratación del Servicio se realiza a través del formulario de registro en la página web corporativa de (<a href={`${env('PORTAL_URL')}/signup`} className="text-decoration-none">MarketSms</a>). Una vez registrada la cuenta, se enviarán al Cliente las credenciales de acceso y las instrucciones para operar. La cuenta será validada tras la primera adquisición. Durante la vigencia del contrato, el Cliente puede adquirir paquetes de envío de SMS. La cancelación del Servicio implica la finalización del contrato según lo estipulado en la cláusula 11.
          <li className="text-dk-blue fs-6 fw-semibold mb-2 mt-3">PRECIO Y FORMA DE PAGO</li>
          El precio del Servicio se detalla en la sección de "Precios" de la página web, con el añadido del Impuesto sobre el Valor Añadido (IVA) o impuesto equivalente. MarketSms se reserva el derecho de modificar el precio, siendo público en todo momento. El precio se abona al realizar la compra mediante los métodos de pago disponibles en la web. MarketSms emitirá facturas mensuales por las adquisiciones realizadas.
          <li className="text-dk-blue fs-6 fw-semibold mb-2 mt-3">FUNCIONALIDADES DEL SERVICIO</li>
          El Servicio incluye conectividad y envío de SMS. Otras funcionalidades disponibles en la página web son gratuitas y sujetas a cambios por parte de MarketSms. El Cliente puede acceder al histórico de SMS enviados y datos de contacto almacenados durante los últimos 12 meses.
          <li className="text-dk-blue fs-6 fw-semibold mb-2 mt-3">NIVEL DEL SERVICIO</li>
          MarketSms se compromete a cumplir los niveles de servicio (SLA) detallados en el Anexo I del contrato.
          <li className="text-dk-blue fs-6 fw-semibold mb-2 mt-3">OBLIGACIONES DEL CLIENTE</li>
          El Cliente debe pagar el precio del Servicio y garantizar la idoneidad y seguridad de sus sistemas. Debe utilizar el Servicio de acuerdo con la legislación aplicable y evitar acciones que puedan dañar los sistemas de MarketSms o terceros.
          <li className="text-dk-blue fs-6 fw-semibold mb-2 mt-3">OBLIGACIONES DE MARKETSMS</li>
          MarketSms se compromete a mantener la infraestructura necesaria para prestar el Servicio y cumplir con las obligaciones del contrato.
          <li className="text-dk-blue fs-6 fw-semibold mb-2 mt-3">RESPONSABILIDAD</li>
          MarketSms será responsable por daños directos causados por incumplimiento del contrato. La responsabilidad total de MarketSms se limita al importe recibido por el Servicio objeto de reclamación.
          <li className="text-dk-blue fs-6 fw-semibold mb-2 mt-3">PROPIEDAD INTELECTUAL</li>
          MarketSms otorga al Cliente una licencia de uso del software y contenidos de la plataforma para acceder al Servicio. El Cliente debe utilizar el software únicamente para los fines del Servicio.
          <li className="text-dk-blue fs-6 fw-semibold mb-2 mt-3">TERMINACIÓN</li>
          El contrato puede terminar por acuerdo mutuo, voluntad de una de las partes con preaviso, o por incumplimiento grave de alguna de las partes.
          <li className="text-dk-blue fs-6 fw-semibold mb-2 mt-3">CESIÓN</li>
          MarketSms puede ceder el contrato a otra entidad previa comunicación al Cliente.
          <li className="text-dk-blue fs-6 fw-semibold mb-2 mt-3">PROTECCIÓN DE DATOS</li>
          Las partes se comprometen a tratar los datos personales de acuerdo con la normativa vigente. MarketSms actuará como encargado del tratamiento y se compromete a cumplir con las obligaciones establecidas en la cláusula.
          <li className="text-dk-blue fs-6 fw-semibold mb-2 mt-3">CONFIDENCIALIDAD</li>
          Ambas partes mantendrán la confidencialidad de la información obtenida durante la prestación del servicio.
          <li className="text-dk-blue fs-6 fw-semibold mb-2 mt-3">REVENTA</li>
          El Cliente puede revender el Servicio a terceros asumiendo todas las obligaciones del contrato. Los clientes del revendedor contratan directamente con él.
          <li className="text-dk-blue fs-6 fw-semibold mb-2 mt-3">MISCELÁNEA</li>
          El contrato y sus anexos constituyen el acuerdo completo entre las partes. En caso de conflicto, prevalecerá lo establecido en el contrato. La nulidad de alguna cláusula no afectará la validez del resto del contrato.
          {/* <li className="text-dk-blue fs-6 fw-semibold mb-2 mt-3">LEY APLICABLE Y JURISDICCIÓN</li>
          El contrato se rige por las leyes de Colombia. Para cualquier disputa, las partes se someten a la jurisdicción de los tribunales de Barcelona. */}
        </ol>
      </CContainer>

    </>
  );
};
