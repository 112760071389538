import React from "react";

interface ContactUsProps {

}

export const ContactUS: React.FC<ContactUsProps> = ({ }: ContactUsProps) => {
    return (
        <>
            <div className="container my-5 pt-5">
                <div className="row g-5">
                    <div className="col-md-6">
                        <h1>Póngase en contacto con nosotros</h1>
                        <p className="mx-auto fs-5 mt-4 text-body-tertiary">Envíe la información de su empresa y un representante de MarketSms se comunicará con usted dentro de las 24 horas.</p>

                        {/* <form className="row g-3 my-5">
                            <div className="form-floating mb-3 col-md-6">
                                <input type="text" className="form-control" id="floatingPassword" placeholder="Password"/>
                                <label htmlFor="floatingPassword">Nombre</label>
                            </div>
                            <div className="form-floating mb-3 col-md-6">
                                <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com"/>
                                <label htmlFor="floatingInput">Correo electronico</label>
                            </div>
                            <div className="col-12">
                                <div className="form-floating">
                                    <textarea className="form-control" placeholder="Escribe tu mensaje" id="floatingTextarea2" style={{height: '100px'}}></textarea>
                                    <label htmlFor="floatingTextarea2">Mensaje</label>
                                </div>
                            </div>
                            <div className="col-12 d-flex justify-content-end">
                                <button className="btn btn-danger px-4 mt-3 fs-5">Enviar</button>
                            </div>
                        </form> */}
                        <hr />
                        <div className="mt-5">
                            <h1 className="mb-5">Detalles de la compañía</h1>
                            <p className="mx-auto fs-5"><strong>Código Postal: </strong> 154640</p>
                            <p className="mx-auto fs-5"><strong>Dirección: </strong>Carrera 13 #28 - 03 Chiquinquirá, Boyacá</p>
                            <p className="mx-auto fs-5"><strong>Correo electrónico: </strong> <a href="mailto:info@marketsms.co" className="text-decoration-none">info@marketsms.co</a></p>
                            <p className="mx-auto fs-5"><strong>Número de teléfono: </strong><a href="tel:573042575514" className="text-decoration-none">573042575514</a></p>
                            <p className="mx-auto fs-5"><strong>Escribenos al WhatsApp: </strong><a href="https://wa.me/message/4WHCGDBNVUMEJ1" className="text-decoration-none">573042575514</a></p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2360.9358295625375!2d-73.81168574558606!3d5.62519092225597!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e41c9b346894941%3A0x94254f240973324!2sCoeducadores%20Barrio%20Boyaca!5e0!3m2!1sen!2sco!4v1699504100252!5m2!1sen!2sco" width="600" height="1000" loading="lazy"></iframe>
                    </div>
                </div>

            </div>
        </>
    )
}