import { CContainer } from "@coreui/react-pro";
import React from "react";

interface SmsProps { }

export const Sms: React.FC<SmsProps> = ({ }: SmsProps) => {

  return (
    <>
      <CContainer>
        <h1 className="text-center my-3 fw-bold">
          Sms
        </h1>
      </CContainer>
    </>
  );
};