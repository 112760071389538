import React, { useEffect, useState } from "react"
import { Plans } from "../../models/Plans";
import * as MarketsmsService from "../../services/MarketsmsService";
import { CImage } from "@coreui/react-pro";
import { env } from "../../Utils/Environment";
interface PriceProps {

}

export const Price: React.FC<PriceProps> = ({ }: PriceProps) => {
  const [listPlans, setListPlans] = useState<Plans[]>([]);
  useEffect(() => {
    getListPlansByStatus();
  }, []);

  const getListPlansByStatus = async () => {
    var items = null;
    var now_time = Math.floor(Date.now() / 1000);
    var old_time = parseInt((localStorage.getItem('PlansModelTime') ? localStorage.getItem('PlansModelTime') : 0) + "");

    if (old_time + 60 < now_time) {
      const res = await MarketsmsService.getPlansByStatus();
      if (res.status == 200) {
        localStorage.setItem('PlansModel', JSON.stringify(res.data?.items));
        localStorage.setItem('PlansModelTime', '' + now_time);
      }
      items = res.data?.items;
    } else {
      items = JSON.parse(localStorage.getItem('PlansModel') + "");
    }

    if (items != null) {
      setListPlans(items);
    }
  };

  return (
    <div className="container mb-5">
      <h1 className="text-center my-4 fw-bold">Gasta menos, haz más</h1>
      <div className="d-flex">
        <p className="text-center mx-auto fs-5 text-body-tertiary">
          Precios simples y asequibles sin contratos, obligaciones ni tarifas de instalación.
        </p>
      </div>
      <div className="row mt-3 g-4">
        {(listPlans).map((plan, key) => (
          <div key={key} className="col-sm-3 mb-3 mb-sm-0">
            <div className="card shadow p-2 bg-white rounded-5 border border-light h-100">
              <div className="card-body d-flex flex-column ">
                <div className="text-center">
                  <CImage src={plan.logo} width={120} />
                </div>
                <h5 className="fw-bold mb-3 mt-3 text-center" style={{ color: plan.color }}>{plan.name.toUpperCase()}</h5>
                <h6 className="card-title fs-6 text-body-tertiary">{plan.description}</h6>
                <p className="card-text text-center fs-5 text-dark fw-medium"><span className=" text-danger fw-bold fs-3 ">$ {plan.value.slice(0, -3)}</span> + iva</p>
                <ul className="list-group list-group-flush p-0">
                  {plan.service_types.map((serviceType, serviceIndex) => (
                    <li key={serviceIndex} className="list-group-item">
                      <label className="text-capitalize text-body-tertiary">{serviceType.name} c/u</label>
                      <h5>$ {serviceType.price_per_message.slice(0, -3)}</h5>
                    </li>
                  ))}
                </ul>
                <div className="d-flex pt-2 mb-2 justify-content-center mt-auto">
                  <button className="btn btn-danger shadow px-4 py-2 rounded-5">
                    <a href={`${env('PORTAL_URL')}/user/administration/accounts/plan/${plan.id}`} className="text-decoration-none text-light">
                      Comprar&nbsp;&nbsp;<i className="bi bi-chevron-right"></i></a></button>
                </div>

              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};