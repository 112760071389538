import { CContainer } from "@coreui/react-pro";
import React from "react";

import { env } from "../../Utils/Environment";
interface PolicyPrivacyProps { }export const PolicyPrivacy: React.FC<PolicyPrivacyProps> = ({ }: PolicyPrivacyProps) => {

  return (
    <>      
      {/* <CContainer className="fs-6 my-4">
      <h1 className="my-5 text-center text-dk-blue">Política de Privacidad</h1>
      <p className="text-info">Fecha de última actualización: 14/04/2024</p>
      <p>Nuestro objetivo es ser lo más transparentes posible con nuestros usuarios y clientes sobre los datos personales que recopilamos. Su privacidad es importante para MarketSms.</p>
      <p>MarketSms opera el sitio web <a href={`${env('PUBLIC_URL')}`} className="text-decoration-none">MarketSms</a></p>
      <p>Esta página le informa sobre nuestras políticas con respecto a la recopilación, uso y divulgación de información personal cuando usted utiliza nuestro Servicio.</p>
      <p>Utilizamos su información personal para proporcionar y mejorar el Servicio. Al utilizar el Servicio, usted acepta la recopilación y el uso de información de acuerdo con esta política. A menos que se defina lo contrario en esta Política de Privacidad, los términos utilizados en esta Política de Privacidad tienen los mismos significados que en nuestros <a href={`${env('PUBLIC_URL')}/termsandconditions`} className="text-decoration-none">Términos y Condiciones</a>.</p>
      <p className="mt-1 text-dk-blue fs-3 fw-semibold">Recopilación y Uso de Información</p>
      <p>Recopilamos varios tipos diferentes de información con diversos fines para proporcionar y mejorar nuestro Servicio.</p>
      <p className="mt-3 text-dk-blue fs-4 fw-semibold">Tipos de Datos Recopilados</p>
      <p className="mt-2 text-dk-blue fs-5 fw-semibold">Datos Personales</p>
      <p>Al utilizar nuestro servicio, es posible que le solicitemos que nos proporcione cierta información de identificación personal que puede incluir, entre otros, su dirección de correo electrónico, nombre, número de teléfono, fecha de nacimiento u otros datos para contactarlo o identificarlo.</p>
      <p className="mt-2 text-dk-blue fs-3 fw-semibold">Uso de Datos</p>
      <p>Utilizamos los datos recopilados para diversos fines, incluyendo:</p>
      <ul>
        <li>Proporcionar y mantener nuestro Servicio</li>
        <li>Notificarle cambios en nuestro Servicio</li>
        <li>Permitirle participar en funciones interactivas de nuestro Servicio cuando decida hacerlo</li>
        <li>Proporcionar asistencia al cliente</li>
        <li>Recopilar análisis o información valiosa para que podamos mejorar nuestro Servicio</li>
        <li>Monitorear el uso de nuestro Servicio</li>
        <li>Detectar, prevenir y abordar problemas técnicos</li>
      </ul>
      <p className="mt-2 text-dk-blue fs-4 fw-semibold">Proveedor de Servicios a Empresas</p>
      <p>MarketSms actúa como proveedor de tecnología para otras empresas, ofreciendo servicios de mensajería de texto. Es importante destacar que los datos recopilados son utilizados exclusivamente para proporcionar el Servicio a nuestros clientes y no se comparten entre ellos. Cada cliente tiene acceso únicamente a sus propios datos y los datos de sus usuarios, garantizando así la privacidad y confidencialidad de la información.</p>
      <p className="mt-2 text-dk-blue fs-4 fw-semibold">Responsabilidad sobre Datos Proporcionados por Clientes</p>
      <p>Las empresas que utilizan nuestros servicios son responsables de la información que nos proporcionan sobre sus clientes finales. MarketSms no se hace responsable de la exactitud, integridad o legalidad de los datos proporcionados por estas empresas. Es responsabilidad de cada empresa asegurarse de que la información que nos proporcionan cumple con todas las leyes y regulaciones de privacidad aplicables. MarketSms actúa únicamente como un procesador de datos y no asume responsabilidad alguna por los datos proporcionados por nuestros clientes empresariales.</p>
      <p className="mt-2 text-dk-blue fs-4 fw-semibold">Transferencia de Datos</p>
      <p>Su información, incluidos los Datos Personales, puede ser transferida a y mantenida en:</p>
      <ul>
        <li><strong>Ubicaciones:</strong> Nuestras computadoras y servidores están ubicados en Colombia, Estados Unidos y la Unión Europea.</li>
        <li><strong>Medidas de Protección:</strong> Implementamos medidas de seguridad adecuadas para proteger sus datos personales conforme a las leyes aplicables de protección de datos. Esto incluye el uso de tecnologías de cifrado y almacenamiento seguro de datos.</li>
      </ul>
      <p>Si usted se encuentra fuera de Colombia y elige proporcionarnos información, tenga en cuenta que transferimos los datos, incluidos los Datos Personales, a Colombia y los procesamos allí.</p>
      <p>Su consentimiento a esta política de privacidad seguido de su envío de dicha información representa su acuerdo con dicha transferencia.</p>
      <p className="mt-2 text-dk-blue fs-4 fw-semibold">Divulgación de Datos</p>
      <p className="mt-2 text-dk-blue fs-5 fw-semibold">Requisitos Legales</p>
      <p>Podemos divulgar sus Datos Personales de buena fe cuando creamos que dicha acción es necesaria para:</p>
      <ul>
        <li>Cumplir con una obligación legal</li>
        <li>Proteger y defender los derechos o la propiedad de MarketSms</li>
        <li>Prevenir o investigar posibles irregularidades en relación con el Servicio</li>
        <li>Proteger la seguridad personal de los usuarios del Servicio o del público</li>
        <li>Protegernos contra la responsabilidad legal</li>
      </ul>
      <p className="mt-2 text-dk-blue fs-4 fw-semibold">Seguridad de los Datos</p>
      <p>La seguridad de sus datos es importante para nosotros, pero recuerde que ningún método de transmisión por Internet o método de almacenamiento electrónico es 100% seguro. Si bien nos esforzamos por utilizar medios comercialmente aceptables para proteger sus Datos Personales, no podemos garantizar su absoluta seguridad.</p>
      <p className="mt-4 text-dk-blue fs-3 fw-semibold">Enlaces a Otros Sitios</p>
      <p>Nuestro Servicio puede contener enlaces a otros sitios que no son operados por nosotros. Si hace clic en un enlace de un tercero, será dirigido al sitio de ese tercero. Le recomendamos encarecidamente que revise la Política de Privacidad de cada sitio que visite.</p>
      <p className="mt-2 text-dk-blue fs-3 fw-semibold">Cambios a Esta Política de Privacidad</p>
      <p>Podemos actualizar nuestra Política de Privacidad de vez en cuando. Le notificaremos cualquier cambio publicando la nueva Política de Privacidad en esta página. Le informaremos por correo electrónico y/o mediante un aviso destacado en nuestro Servicio, antes de que el cambio entre en vigencia y actualizaremos la "fecha de vigencia" en la parte superior de esta Política de Privacidad. Le recomendamos que revise esta Política de Privacidad periódicamente para ver si se han realizado cambios. Los cambios a esta Política de Privacidad son efectivos cuando se publican en esta página.</p>
      <p className="mt-2 text-dk-blue fs-3 fw-semibold">Contacto</p>
      <p className="mb-2">Si tiene alguna pregunta sobre esta Política de Privacidad, puede contactarnos:</p>
      <p><strong>Correo electrónico: </strong><a href="mailto:info@marketsms.co" className="text-decoration-none">info@marketsms.co</a></p>
      <p><strong>Teléfono: </strong>+57 304 257 5514</p>
      <p><strong>A través de nuestra página web: </strong><a href="http://www.marketsms.co" className="text-decoration-none">www.marketsms.co</a></p>
    </CContainer> */}
    <CContainer className="fs-6 my-4">
      <h1 className="my-5 text-center text-dk-blue">Política de Privacidad</h1>
      <p className="text-info">Fecha de última actualización: 28/05/2024</p>
      <p>En MarketSms, nos comprometemos a ser lo más transparentes posible con nuestros usuarios y clientes respecto a la recopilación, uso y divulgación de sus datos personales. Su privacidad es de suma importancia para nosotros.</p>
      <p>MarketSms opera el sitio web <a href="https://marketsms.co" className="text-decoration-none">MarketSms.co</a>.</p>
      <p>Esta Política de Privacidad explica nuestras prácticas en relación con la recopilación, uso y divulgación de su información personal cuando utiliza nuestro Servicio y las opciones que tiene asociadas con esa información. Al utilizar el Servicio, usted acepta la recopilación y el uso de información conforme a esta política.</p>

      <h2 className="mt-4 text-dk-blue fs-3 fw-semibold">Recopilación y Uso de Información</h2>
      <p>Recopilamos varios tipos de información con diversos fines para proporcionar y mejorar nuestro Servicio.</p>

      <h3 className="mt-3 text-dk-blue fs-4 fw-semibold">Tipos de Datos Recopilados</h3>
      <h4 className="mt-2 text-dk-blue fs-5 fw-semibold">Datos Personales</h4>
      <p>Podemos recopilar datos de identificación personal ("Datos Personales") que pueden incluir, entre otros:</p>
      <ul>
        <li>Dirección de correo electrónico</li>
        <li>Nombre y apellidos</li>
        <li>Número de teléfono</li>
        <li>Fecha de nacimiento</li>
        <li>Dirección de residencia</li>
        <li>Otra información para contactarlo o identificarlo</li>
      </ul>

      <h4 className="mt-3 text-dk-blue fs-5 fw-semibold">Datos de Uso</h4>
      <p>Podemos recopilar información sobre cómo se accede y se utiliza el Servicio ("Datos de Uso"). Estos Datos de Uso pueden incluir información como la dirección de Protocolo de Internet (IP) de su computadora, tipo de navegador, versión del navegador, las páginas de nuestro Servicio que visita, la hora y fecha de su visita, el tiempo que pasa en esas páginas, identificadores únicos de dispositivos y otros datos de diagnóstico.</p>

      <h3 className="mt-3 text-dk-blue fs-4 fw-semibold">Uso de Datos</h3>
      <p>MarketSms utiliza los datos recopilados para diversos propósitos, tales como:</p>
      <ul>
        <li>Proporcionar y mantener el Servicio</li>
        <li>Notificarle sobre cambios en nuestro Servicio</li>
        <li>Permitirle participar en funciones interactivas de nuestro Servicio cuando decida hacerlo</li>
        <li>Proporcionar asistencia al cliente</li>
        <li>Recopilar análisis o información valiosa para que podamos mejorar nuestro Servicio</li>
        <li>Monitorear el uso del Servicio</li>
        <li>Detectar, prevenir y abordar problemas técnicos</li>
      </ul>

      <h3 className="mt-3 text-dk-blue fs-4 fw-semibold">Proveedor de Servicios a Empresas</h3>
      <p>MarketSms actúa como proveedor de tecnología para otras empresas, ofreciendo servicios de mensajería de texto. Es importante destacar que los datos recopilados son utilizados exclusivamente para proporcionar el Servicio a nuestros clientes y no se comparten entre ellos. Cada cliente tiene acceso únicamente a sus propios datos y los datos de sus usuarios, garantizando así la privacidad y confidencialidad de la información.</p>

      {/* <h3 className="mt-3 text-dk-blue fs-4 fw-semibold">Transferencia de Datos</h3>
      <p>Su información, incluidos los Datos Personales, puede ser transferida a y mantenida en:</p>
      <ul>
        <li><strong>Ubicaciones:</strong> Nuestras computadoras y servidores están ubicados en [indique las ubicaciones específicas, por ejemplo, Colombia, Estados Unidos, la Unión Europea, etc.].</li>
        <li><strong>Medidas de Protección:</strong> Implementamos medidas de seguridad adecuadas para proteger sus datos personales conforme a las leyes aplicables de protección de datos. Esto incluye el uso de tecnologías de cifrado y almacenamiento seguro de datos.</li>
      </ul>
      <p>Si se encuentra fuera de Colombia y elige proporcionarnos información, tenga en cuenta que transferimos los datos, incluidos los Datos Personales, a Colombia y los procesamos allí.</p>
      <p>Su consentimiento a esta Política de Privacidad seguido de su envío de dicha información representa su acuerdo con dicha transferencia.</p> */}

      <h3 className="mt-3 text-dk-blue fs-4 fw-semibold">Divulgación de Datos</h3>
      <h4 className="mt-2 text-dk-blue fs-5 fw-semibold">Requisitos Legales</h4>
      <p>MarketSms puede divulgar sus Datos Personales de buena fe cuando creamos que dicha acción es necesaria para:</p>
      <ul>
        <li>Cumplir con una obligación legal</li>
        <li>Proteger y defender los derechos o la propiedad de MarketSms</li>
        <li>Prevenir o investigar posibles irregularidades en relación con el Servicio</li>
        <li>Proteger la seguridad personal de los usuarios del Servicio o del público</li>
        <li>Protegernos contra la responsabilidad legal</li>
      </ul>

      <h3 className="mt-3 text-dk-blue fs-4 fw-semibold">Seguridad de los Datos</h3>
      <p>La seguridad de sus datos es importante para nosotros. Utilizamos medidas comercialmente aceptables para proteger sus Datos Personales, pero recuerde que ningún método de transmisión por Internet o método de almacenamiento electrónico es 100% seguro. No podemos garantizar su seguridad absoluta.</p>

      <h3 className="mt-3 text-dk-blue fs-4 fw-semibold">Enlaces a Otros Sitios</h3>
      <p>Nuestro Servicio puede contener enlaces a otros sitios que no son operados por nosotros. Si hace clic en un enlace de un tercero, será dirigido al sitio de ese tercero. Le recomendamos encarecidamente que revise la Política de Privacidad de cada sitio que visite.</p>

      <h3 className="mt-3 text-dk-blue fs-4 fw-semibold">Cambios a Esta Política de Privacidad</h3>
      <p>Podemos actualizar nuestra Política de Privacidad periódicamente. Le notificaremos cualquier cambio publicando la nueva Política de Privacidad en esta página y, si los cambios son significativos, se lo notificaremos a través de un aviso destacado en nuestro Servicio y/o por correo electrónico antes de que el cambio entre en vigencia. Actualizaremos la "fecha de vigencia" en la parte superior de esta Política de Privacidad. Le recomendamos que revise esta Política de Privacidad periódicamente para estar informado de cualquier cambio.</p>

      <h3 className="mt-3 text-dk-blue fs-4 fw-semibold">Contacto</h3>
      <p>Si tiene alguna pregunta sobre esta Política de Privacidad, no dude en contactarnos:</p>
      <ul>
        <li><strong>Correo electrónico:</strong> <a href="mailto:info@marketsms.co" className="text-decoration-none">info@marketsms.co</a></li>
        <li><strong>Teléfono:</strong> +57 304 257 5514</li>
        <li><strong>A través de nuestra página web:</strong> <a href="http://www.marketsms.co" className="text-decoration-none">www.marketsms.co</a></li>
      </ul>
    </CContainer>
    </>
  );
};
