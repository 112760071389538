import { useEffect } from 'react';
import './scss/style.scss'
import "@coreui/coreui-pro/dist/js/coreui.bundle.min.js";
import "@coreui/coreui-pro/dist/css/coreui.min.css";
import "primereact/resources/themes/nova-accent/theme.css";
import "primereact/resources/primereact.min.css";
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
// import SideBar from './layout/NavBar';
import { env } from './Utils/Environment';
import * as MarketsmsService from "./services/MarketsmsService";
import { getDeviceData } from './Utils/BaseApp';
import { getToken as getTokenFirebase } from "firebase/messaging";
import { messaging } from './firebase/FirebaseConfig';
import { ToastContainer } from 'react-toastify';
import Page404 from './views/pages/page404/Page404';
import { Home } from './views/home/Home';
import SideBar from './layout/NavBar';
import NavBar from './layout/NavBar';
import { Price } from './views/plans/Price';
import { ErrorCodes } from './views/errorcodes/ErrorCodes';
import { ContactUS } from './views/contactus/ContactUs';
import { TermsAndConditions } from './views/termsandconditions/TermsAndConditions';
import { PolicyPrivacy } from './views/policyprivacy/PolicyPrivacy';
import { Whatsapp } from './views/services/Whatsapp';
import { Sms } from './views/services/Sms';


function App() {

  async function saveDevice() {
    getDeviceData().then((response) => {
      var device_id = response.device_id;
      var device_model = response.browser;
      var device_os = response.os;
      localStorage.setItem('device_id', device_id)
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          getTokenFirebase(messaging, {
            vapidKey: env('VAPID_KEY'),
          }).then(token => {
            MarketsmsService.saveDevice({ token_notification_firebase: token, device_id: device_id, device_model: device_model, device_os: device_os, app_mobile_version: env('APP_VERSION') })
          });
        } else if (permission === "denied") {
          MarketsmsService.saveDevice({ device_id: device_id, device_model: device_model, device_os: device_os, app_mobile_version: env('APP_VERSION') })
        } else {
          MarketsmsService.saveDevice({ device_id: device_id, device_model: device_model, device_os: device_os, app_mobile_version: env('APP_VERSION') })
        }
      });
    })
  }

  useEffect(() => {
    saveDevice()
  }, []);

  return (
    <BrowserRouter>
      <ToastContainer />
      <Routes>
        <Route path='/' element={<NavBar ><Home /></NavBar>} />
        <Route path='/plans' element={<NavBar ><Price /></NavBar>} />
        <Route path='/errorcodes' element={<NavBar ><ErrorCodes /></NavBar>} />
        <Route path='/contactus' element={<NavBar ><ContactUS /></NavBar>} />
        <Route path='/termsandconditions' element={<NavBar ><TermsAndConditions /></NavBar>} />
        <Route path='/policyprivacy' element={<NavBar ><PolicyPrivacy /></NavBar>} />
        <Route path='/services/whatsapp' element={<NavBar ><Whatsapp /></NavBar>} />
        <Route path='/services/sms' element={<NavBar ><Sms /></NavBar>} />
        <Route path='*' element={<Page404 />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;