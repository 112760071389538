import { CAccordion, CAccordionBody, CAccordionHeader, CAccordionItem, CButton, CCardImage, CCarousel, CCarouselCaption, CCarouselItem, CCol, CContainer, CFormInput, CImage, CLink, CNavLink, CRow } from "@coreui/react-pro";
import React, { useEffect, useState } from "react";
import textMessaging from "../../assets/home/text-messaging.png";
import campaignWhatsAppSms from "../../assets/home/vector-campaign.png";
import marketing from "../../assets/home/vector-marketing.png";
import phone from "../../assets/home/phone.svg";
import send from "../../assets/home/send.svg";
import alert from "../../assets/home/alert.svg";
import subscribers from "../../assets/home/subscribers.svg";
import segmentation from "../../assets/home/subs-segmentation.svg";
import checks from "../../assets/home/checks.svg";
import borderArrow from "../../assets/home/border-arrow.svg";
import home from "../../assets/home/home.svg";
import hearth from "../../assets/home/hearth.svg";
import bank from "../../assets/home/bank.svg";
import restaurants from "../../assets/home/restaurants.svg";
import schools from "../../assets/home/schools.svg";
import sales from "../../assets/home/sales.svg";
import campaign from "../../assets/whatsapp/campaign.png";
import logo from "../../assets/whatsapp/logo.png";
import background from "../../assets/whatsapp/background.png";
import whatsAppMarketing from "../../assets/whatsapp/marketing.png";
import { BIcon } from "../../components/icons/BIcon";
import RadioComponent from "../../components/radio/RadioComponent";
import text from "../../assets/whatsapp/text.png";
import media from "../../assets/whatsapp/media.png";
import quickReply from "../../assets/whatsapp/quick-reply.png";
import callToAction from "../../assets/whatsapp/call-to-action.png";
import listPicker from "../../assets/whatsapp/list-picker.png";
import whatsappCard from "../../assets/whatsapp/card.png";
import authentication from "../../assets/whatsapp/authentication.png";
import iphone from "../../assets/iphone.png";
import iphone2 from "../../assets/iphone2.png";
import { Link, NavLink } from "react-router-dom";
import { env } from "../../Utils/Environment";

interface HomeProps { }

export const Home: React.FC<HomeProps> = ({ }: HomeProps) => {
  console.log("object");

  const options = [
    { id: 1, image_src: text, text: 'Texto', description: 'Permite el envió mensajes de texto simples y directos. Es ideal para comunicaciones breves y claras, como recordatorios, confirmaciones o mensajes informativos.' },
    { id: 2, image_src: media, text: 'Media', description: 'Con esta opción, puedes enviar mensajes que incluyan imágenes, videos o archivos multimedia. Es útil para compartir contenido visualmente atractivo o informativo.' },
    { id: 3, image_src: quickReply, text: 'Respuesta rápida', description: 'Esta plantilla proporciona opciones predefinidas para que los usuarios elijan y respondan rápidamente. Es ideal para encuestas, cuestionarios o cualquier situación donde se necesite una respuesta rápida y concreta.' },
    { id: 4, image_src: callToAction, text: 'Llamada de acción', description: 'Con esta plantilla, puedes guiar a los usuarios para que realicen una acción específica, como hacer una reserva, programar una cita o visitar un enlace externo. Es efectiva para impulsar la interacción y la participación del usuario.' },
    { id: 5, image_src: listPicker, text: 'Selector de lista', description: 'Esta opción permite mostrar una lista de opciones entre las cuales los usuarios pueden elegir. Es útil para ofrecer múltiples opciones o categorías, como menús de restaurantes, catálogos de productos.' },
    { id: 6, image_src: whatsappCard, text: 'Tarjeta de WhatsApp', description: 'Esta plantilla muestra información estructurada en forma de tarjeta, con imágenes y texto descriptivo. Es útil para presentar productos, servicios o detalles específicos de manera visualmente atractiva y organizada.' },
    { id: 7, image_src: authentication, text: 'Autenticación con WhatsApp', description: 'Permite la autenticación de usuarios a través de WhatsApp, proporcionando una capa adicional de seguridad y facilitando el acceso a servicios o aplicaciones mediante la verificación del número de teléfono asociado a la cuenta de WhatsApp del usuario.' },
  ];

  const [selectedOptionId, setSelectedOptionId] = useState<number>(1);

  const handleOptionChange = (id: number) => {
    setSelectedOptionId(id);
  };

  function formatTime(date: any) {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const amOrPm = hours >= 12 ? 'pm' : 'am';
    const formattedHours = hours % 12 || 12; // Convertir a formato de 12 horas
    return `${formattedHours}:${minutes < 10 ? '0' : ''}${minutes} ${amOrPm}`;
  }

  return (
    <>
      <div className="container">
        <h1 className="text-center my-5 fw-bold">
          Excelente marketing con servicio de mensajería de<br />texto empresarial
        </h1>

        <div className="d-flex">
          <p className="text-center mx-auto fs-5 ">
            Elimine el ruido al asegurarse de que los clientes potenciales lean sus mensajes en segundos, cada vez. Comience en minutos y elija entre nuestra variedad de 9 planes diseñados para adaptarse a las necesidades de su negocio.
          </p>
        </div>

        <div className="row mt-5">
          <div className="col-md-6">
            <img src={campaignWhatsAppSms} className="h-100" alt="" />
          </div>
          <div className="col-md-6">
            <h2 className="fw-bold">Campañas de WhatsApp y mensajes de texto para el crecimiento de su negocio</h2>
            <p className="mt-4 fs-5 ">Con MarketSms, el envío de campañas de publicidad se siente tan natural como puede ser. La comunicación breve y clara proporciona a las empresas un canal directo a sus suscriptores. No se pierda las altas conversiones que obtendrá al conectar de manera efectiva con su audiencia.</p>
            <button className="btn btn-danger p-3 mt-3 fs-5">
              <NavLink to={`${env('PORTAL_URL')}/login`} className="text-decoration-none fw-medium text-light">
                Comienza ya!
              </NavLink>
            </button>
          </div>
        </div>


        <div className="row mt-5">

          <div className="col-md-6">
            <h2 className="fw-bold">Publicidad de texto sencilla dentro de un panel simple</h2>
            <p className="mt-4 fs-5 ">MarketSms te brinda la capacidad de crear, ejecutar, monitorear y optimizar fácilmente campañas de mensajes directos y OTP a través de SMS y WhatsApp. Nuestra plataforma ofrece automatización completa, lo que significa que puedes gestionar todo sin tener que alternar entre aplicaciones, asegurando así una experiencia fluida y eficiente.</p>
          </div>
          <div className="col-md-6">
            <img src={marketing} className="h-100" alt="" />
          </div>
        </div>

        {/* <div className="row mt-5">
          <div className="col-md-6">
            <h1 className="fw-bold">Publicidad de texto sencilla dentro de un panel simple</h1>
          </div>
          <div className="col-md-6">
            <p className="fs-4">MarketSms le permite crear, ejecutar, monitorear y optimizar sin esfuerzo secuencias de correo electrónico y SMS de alta conversión con 100% automatización sin cambiar entre aplicaciones.</p>
          </div>
        </div> */}

        <h1 className="text-center mt-5 fw-bolder mb-2">Realiza la publicidad de tu negocio <br /> a través de WhatsApp</h1>
        <p className="fs-5 mt-4">¡Optimiza tus campañas publicitarias con nuestras plantillas de WhatsApp! Ahora, no solo puedes enviar mensajes personalizados, sino que también puedes aprovechar nuestras plantillas predefinidas para agilizar y mejorar tus comunicaciones con tus clientes. Las siguientes plantillas se pueden utilizar en el envío de tus campañas publicitarias a tus clientes, garantizando un mensaje claro, consistente y atractivo. ¡Potencia tu estrategia de marketing con nuestras plantillas y haz que tus mensajes destaquen entre la multitud!</p>
        <div className="row pt-5 d-flex">
          <div className="col-md-9">
            <div>
              <div className="row g-4">
                <RadioComponent options={options} selectedOptionId={selectedOptionId} onChange={handleOptionChange} />
              </div>
            </div>

          </div>
          <div className="col-md-3 bg-transparent h-100"
            style={{
              backgroundImage: `url(${iphone2})`,
              // backgroundSize: "cover", // Hace que la imagen cubra completamente el fondo
              paddingTop: "45px",
              paddingLeft: "25px",
              paddingRight: "18px",
              minHeight: "665px",
              maxWidth: "330px",
              marginLeft: "auto",
              marginRight: "auto"
              // backgroundPosition: "center", // Centra la imagen en el contenedor
            }}
          >
            <CRow className="bg-white p-1 rounded-top m-0">
              <CCol className="p-0 pt-2 m-0"
                style={{ maxWidth: "5px" }}
              >
                <BIcon icon="chevron-left" />
              </CCol>
              <CCol sm={2} className="d-flex">
                <CImage src={logo} width={30} height={30} className="rounded-circle m-1" />
              </CCol>
              <CCol sm={6}>
                <p className="m-0 fw-bolder fs-7">{"MarketSms"}</p>
                <p className="m-0 fs-9">Cuenta de negocios</p>
              </CCol>
              <CCol className="p-0 pt-1 m- ms-4">
                <BIcon icon="shop" className="me-3"
                />
                <BIcon icon="telephone-plus"
                />
              </CCol>
            </CRow>
            <div className="bg-light" style={{ minHeight: "555px", maxHeight: "555px", borderBottomLeftRadius: "25px", borderBottomRightRadius: "25px" }}>
              <CContainer fluid className="bg-light position-relative p-2 pb-5" style={{ backgroundImage: `url(${background})`, backgroundSize: 'cover', backgroundPosition: 'center', minHeight: "555px", maxHeight: "555px", borderBottomLeftRadius: "25px", borderBottomRightRadius: "25px" }}>
                {(selectedOptionId == 1) &&
                  <div className="card me-3 mb-2" >
                    <div className="card-body p-2">
                      <p className="card-text m-0 fs-7">Con WhatsApp Marketing podrás promocionar tus productos y servicios 🎉, interactuar de forma directa con tus clientes y cerrar ventas 💸 de manera instantánea. ¡No pierdas la oportunidad de impulsar tu negocio 🚋 de manera significativa! 💎</p>
                      <p className="fs-10 m-0 text-end">{formatTime(new Date())}</p>
                    </div>
                  </div>
                }

                {(selectedOptionId == 2) &&
                  <div className="card me- mb-2" >
                    <div className="card-body p-1">
                      <CCardImage orientation="bottom" src={whatsAppMarketing} className="rounded-1 mb-1" />
                      <p className="card-text m-0 fs-7">Con WhatsApp Marketing podrás promocionar tus productos y servicios 🎉, interactuar de forma directa con tus clientes y cerrar ventas 💸 de manera instantánea. ¡No pierdas la oportunidad de impulsar tu negocio 🚋 de manera significativa! 💎</p>
                      <p className="fs-10 m-0 text-end">{formatTime(new Date())}</p>
                    </div>
                  </div>
                }

                {(selectedOptionId == 3) &&
                  <div className="card me- mb-2" >
                    <div className="card-body p-1">
                      <p className="card-text m-0 fs-7">Con WhatsApp Marketing podrás promocionar tus productos y servicios 🎉, interactuar de forma directa con tus clientes y cerrar ventas 💸 de manera instantánea. ¡No pierdas la oportunidad de impulsar tu negocio 🚋 de manera significativa! 💎</p>
                      <p className="fs-10 m-0 text-end mb-1">{formatTime(new Date())}</p>
                      <p className="fw-semibold text-center border-top p-2 m-0 text-info cursor-pointer fs-8">
                        <BIcon icon="reply-fill" className="me-2" />
                        Mas información
                      </p>
                      <p className="fw-semibold text-center border-top p-2 m-0 text-info cursor-pointer fs-8">
                        <BIcon icon="reply-fill" className="me-2" />
                        Ahora no
                      </p>
                    </div>
                  </div>
                }


                {(selectedOptionId == 4) &&
                  <div className="card me- mb-2" >
                    <div className="card-body p-1">
                      <p className="card-text m-0 fs-7">Con WhatsApp Marketing podrás promocionar tus productos y servicios 🎉, interactuar de forma directa con tus clientes y cerrar ventas 💸 de manera instantánea. ¡No pierdas la oportunidad de impulsar tu negocio 🚋 de manera significativa! 💎</p>
                      <p className="fs-10 m-0 text-end mb-1">{formatTime(new Date())}</p>
                      <p className="fw-semibold text-center border-top p-2 m-0 text-info cursor-pointer fs-8">
                        <BIcon icon="telephone-fill" className="me-2" />
                        Descarga nuesta app
                      </p>
                      <p className="fw-semibold text-center border-top p-2 m-0 text-info cursor-pointer fs-8">
                        <BIcon icon="box-arrow-up-right" className="me-2" />
                        Llamanos
                      </p>
                    </div>
                  </div>
                }


                {(selectedOptionId == 5) &&
                  <div className="card me- mb-2" >
                    <div className="card-body p-1">
                      <CCardImage orientation="bottom" src={whatsAppMarketing} className="rounded-1 mb-1" />
                      <p className="card-text m-0 fs-7">Con WhatsApp Marketing podrás promocionar tus productos y servicios 🎉, interactuar de forma directa con tus clientes y cerrar ventas 💸 de manera instantánea. ¡No pierdas la oportunidad de impulsar tu negocio 🚋 de manera significativa! 💎</p>
                      <p className="fs-10 m-0 text-end mb-1">{formatTime(new Date())}</p>
                      <p className="fw-semibold text-center border-top p-2 m-0 text-info cursor-pointer fs-8">
                        <BIcon icon="list-ul" className="me-2" />
                        Ver productos
                      </p>
                    </div>
                  </div>
                }

                {(selectedOptionId == 6) &&
                  <div className="card me- mb-2" >
                    <div className="card-body p-1">
                      <CCardImage orientation="bottom" src={whatsAppMarketing} className="rounded-1 mb-1" />
                      <p className="card-text m-0 fs-7">Con WhatsApp Marketing podrás promocionar tus productos y servicios 🎉, interactuar de forma directa con tus clientes y cerrar ventas 💸 de manera instantánea. ¡No pierdas la oportunidad de impulsar tu negocio 🚋 de manera significativa! 💎</p>
                      <p className="fs-10 m-0 text-end mb-1">{formatTime(new Date())}</p>
                      <p className="fw-semibold text-center border-top p-2 m-0 text-info cursor-pointer fs-8">
                        <BIcon icon="telephone-fill" className="me-2" />
                        Descarga nuesta app
                      </p>
                      <p className="fw-semibold text-center border-top p-2 m-0 text-info cursor-pointer fs-8">
                        <BIcon icon="box-arrow-up-right" className="me-2" />
                        Llamanos
                      </p>
                    </div>
                  </div>
                }

                {(selectedOptionId == 7) &&
                  <div className="card me- mb-2" >
                    <div className="card-body p-2">
                      <p className="card-text m-0 fs-7">1234 es tu código de verificación, por tu seguridad no compartas este código con nadie. Este código expira en 5 minutos.</p>
                      <p className="fs-10 m-0 text-end">{formatTime(new Date())}</p>

                    </div>
                    <p className="fw-bolder text-center border-top p-2 m-0 cursor-pointer fs-8">
                      <BIcon icon="copy" className="me-2" />
                      Copiar código
                    </p>
                  </div>
                }
              </CContainer>
            </div>

          </div>
        </div>


        {/* <div className="text-center mt-3 py-3">
          <h2 className="fw-bold">Excelente servicio de mensajería de texto para una<br />experiencia de marketing sólida</h2>
          <p className="fs-5 mt-4">Potencia tu campaña de marketing y llega a más clientes más rápido. Atraiga a los clientes con secuencias combinadas de correo electrónico y SMS para obtener resultados potentes y eficaces. ¡Así es como te desenvuelves con MarketSms!</p>
        </div> */}

        {/* <div className="text-center mt-3 py-3">
          <h2 className="fw-bold">Todas las empresas pueden emplear<br />mensajes de texto para su éxito.</h2>
          <p className="fs-5 mt-4">Descubra cómo el servicio de mensajes de texto de MarketSms beneficia a su empresa con una cómoda plataforma de marketing por correo electrónico y SMS. Aproveche el canal de comunicación y publicidad por mensajes de texto más eficaz: el marketing por SMS.</p>
        </div> */}
        <div className="text-center mt-3 py-3">
          <h2 className="fw-bold">Todas las empresas pueden emplear<br />mensajes de texto para su éxito.</h2>
          <p className="fs-5 mt-4">Descubra cómo el servicio de mensajes de texto de MarketSms beneficia a su empresa con una cómoda plataforma de marketing SMS. Aproveche el canal de comunicación y publicidad por mensajes de texto más eficaz: el marketing por SMS y ahora también por WhatsApp.</p>
        </div>

        <div className="row g-4">
          <div className="col-md-4">
            <div className="card h-100">
              <div className="card-body">
                <h4 className="card-title"> <img src={home} alt="" />&nbsp;&nbsp;Minorista</h4>
                <p className="card-text mt-3">Impulsa tus ventas con anuncios de ventas flash y notificaciones en tiempo real, ya sea a través de SMS o WhatsApp. Mantén a tus clientes informados sobre descuentos exclusivos o el estado de sus pedidos para mantenerlos comprometidos y volver a atraer a clientes anteriores.</p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card h-100">
              <div className="card-body">
                <h4 className="card-title"> <img src={hearth} alt="" />&nbsp;&nbsp;Organizaciones sin fines de lucro</h4>
                <p className="card-text mt-3">Facilita la comunicación instantánea con tu comunidad a través del marketing por SMS o WhatsApp. Desde iglesias hasta organizaciones sin fines de lucro, aprovecha esta herramienta para enviar mensajes atractivos y mantener a tus seguidores comprometidos con tu causa.</p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card h-100">
              <div className="card-body">
                <h4 className="card-title"> <img src={bank} alt="" />&nbsp;&nbsp;Bienes raíces</h4>
                <p className="card-text mt-3">Mantén a tus clientes potenciales al tanto de las últimas ofertas inmobiliarias a través de mensajes de texto o WhatsApp. Los agentes inmobiliarios pueden enviar información relevante en cualquier momento para mantener el interés y cerrar ventas más rápido.</p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card h-100">
              <div className="card-body">
                <h4 className="card-title"> <img src={restaurants} alt="" />&nbsp;&nbsp;Restaurantes</h4>
                <p className="card-text mt-3">Crea una conexión más cercana con tus clientes a través del marketing por SMS o WhatsApp. Envía ofertas especiales, promociones de última hora o incluso actualizaciones sobre nuevos platos para mantener a tus clientes comprometidos y fomentar la lealtad.</p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card h-100">
              <div className="card-body">
                <h4 className="card-title"> <img src={schools} alt="" />&nbsp;&nbsp;Escuelas</h4>
                <p className="card-text mt-3">Mantén a padres, alumnos y personal informados sobre actualizaciones importantes en tiempo real mediante mensajes de texto o WhatsApp. Desde cambios en el horario hasta recordatorios de eventos, el marketing por SMS o WhatsApp es una herramienta invaluable para mejorar la comunicación escolar.</p>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card h-100">
              <div className="card-body">
                <h4 className="card-title"> <img src={sales} alt="" />&nbsp;&nbsp;Ventas</h4>
                <p className="card-text mt-3">Sea cual sea tu modelo de negocio, el marketing por SMS o WhatsApp puede ser una herramienta poderosa para tu departamento comercial. Desde promociones especiales hasta seguimiento de clientes potenciales, aprovecha esta forma directa y efectiva de comunicación para impulsar tus ventas y hacer crecer tu negocio.</p>
              </div>
            </div>
          </div>
        </div>


        <div className="my-5 py-5 container-pqr">
          <h1 className="fw-bold text-center mb-5">Preguntas frecuentes sobre mensajes <br />de texto comerciales</h1>

          <CAccordion activeItemKey={1}>
            <CAccordionItem itemKey={1}>
              <CAccordionHeader>¿Qué es el marketing de mensajes SMS?</CAccordionHeader>
              <CAccordionBody>
                El marketing de mensajes SMS , por definición, es el proceso de enviar mensajes de texto a contactos específicos a través de un operador con fines promocionales. Envía notificaciones, alertas, descuentos, seguimientos y muchos otros mensajes de texto.
                Las campañas de SMS tienen la ventaja de ser increíblemente rentables como canal independiente y como canal de apoyo a otros. El marketing de texto, como tal, tiene numerosos beneficios. Se puede implementar en varios modelos de negocio y no requiere una aplicación dedicada ni soluciones de software personalizadas.
              </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem itemKey={2}>
              <CAccordionHeader>¿Cuánto cuesta la publicidad por SMS?</CAccordionHeader>
              <CAccordionBody>
                ¡La publicidad por SMS es una herramienta poderosa y asequible para llegar a tu audiencia donde sea que estén! En [nombre de tu empresa], ofrecemos una variedad de planes de publicidad por SMS diseñados para adaptarse a tus necesidades y presupuesto.
                Nuestros planes comienzan desde $20 c/u y varían según la cantidad de mensajes, la segmentación demográfica y otras opciones personalizadas que desees. Desde pequeñas empresas hasta grandes corporaciones, tenemos el plan perfecto para ti.
                ¿Listo para llevar tu estrategia de marketing al siguiente nivel? ¡Haz clic a continuación para explorar nuestros 9 planes y descubrir cómo podemos ayudarte a alcanzar tus objetivos de marketing!
                <Link to="/plans" className="text-decoration-none"> Explora nuestros planes</Link>
              </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem itemKey={3}>
              <CAccordionHeader>¿Qué tan efectivo es el marketing por SMS?</CAccordionHeader>
              <CAccordionBody>
                El marketing por SMS es probablemente el canal más eficaz. Según la investigación, cuenta con una tasa de apertura y lectura de más del 98%, una tasa de respuesta de casi el 50% y tasas de clics de hasta el 20%. Debido a una participación tan alta, el retorno de la inversión del marketing por SMS es enorme.
              </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem itemKey={4}>
              <CAccordionHeader>¿Cómo recopilar números de teléfono para marketing por mensajes de texto?</CAccordionHeader>
              <CAccordionBody>
                Las empresas pueden crear una lista de SMS mediante formularios de registro y ventanas emergentes que se pueden agregar a los sitios web. Son formularios de generación de leads creados específicamente para adquirir nuevos suscriptores y permitir a los clientes dar su consentimiento mediante una suscripción voluntaria.
                Los proveedores de plataformas de marketing por SMS agregan funciones que permiten a los clientes crear formularios emergentes y de registro gratuitos. Por lo general, se pueden agregar a casi cualquier sitio web con unos pocos clics.
              </CAccordionBody>
            </CAccordionItem>
          </CAccordion>
        </div>
      </div>
    </>
  );
};
